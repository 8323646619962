import { Header } from "components/Common/Header";
import "./BuyerAppInfo.module.scss";
import { Input, Tabs, TextInput } from "@mantine/core";
import { Button } from "components/Common/Button";
import { useEffect, useState } from "react";
import {
  useAddAboutUsMutation,
  useAddContactUsMutation,
  useAddPrivacyPolicyMutation,
  useAddTNCMutation,
  useGetAboutUsQuery,
  useGetContactUsQuery,
  useGetPrivacyPolicyQuery,
  useGetTNCQuery,
  useUpdateAboutUsMutation,
  useUpdateContactUsMutation,
  useUpdatePrivacyPolicyMutation,
  useUpdateTNCMutation,
} from "hooks/Admin/buyerAppInfo";
import FAQSection from "./FAQSection";
import ContactUs from "./ContactUs";
import { getStorageItem } from "services/storage";

type SectionTypes = "about" | "privacy" | "terms" | "FAQ" | "contactUs";

const BuyerAppInfo = () => {
  const [aboutUs, setAboutUs] = useState<string>("");
  const [terms, setTerms] = useState<string>("");

  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");

  const { data: aboutUsLink } = useGetAboutUsQuery();
  const { data: privacyPolicyLink } = useGetPrivacyPolicyQuery();
  const { data: tncLink } = useGetTNCQuery();

  const [updatePolicy] = useUpdatePrivacyPolicyMutation();
  const [updateTerms] = useUpdateTNCMutation();
  const [updateContactUs] = useUpdateContactUsMutation();
  const [addPolicy] = useAddPrivacyPolicyMutation();
  const [addTerms] = useAddTNCMutation();
  const [addContactUs] = useAddContactUsMutation();
  const [addAbout] = useAddAboutUsMutation();
  const [updateAbout] = useUpdateAboutUsMutation();
  const isUser = getStorageItem("role") === "USER";

  useEffect(() => {
    setAboutUs(aboutUsLink?.data?.[0]?.about);
  }, [aboutUsLink]);

  useEffect(() => {
    setPrivacyPolicy(privacyPolicyLink?.data?.[0]?.privacypolicy);
  }, [privacyPolicyLink]);

  useEffect(() => {
    setTerms(tncLink?.data?.[0]?.terms);
  }, [tncLink]);

  const handleAddLink = (section: SectionTypes) => {
    if (section === "about") {
      addAbout(aboutUs);
    } else if (section === "privacy") {
      addPolicy(privacyPolicy);
    } else if (section === "terms") {
      addTerms(terms);
    }
  };

  const handleEditLink = (section: SectionTypes) => {
    if (section === "about") {
      let id = aboutUsLink?.data?.[0]?._id;
      let data = {
        query: {
          _id: id,
        },
        payload: {
          about: aboutUs,
        },
      };
      updateAbout(data)
        .then((res: any) => {
          if (res.statusCode) {
            console.log(res);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (section === "privacy") {
      let id = privacyPolicyLink?.data?.[0]?._id;
      let data = {
        query: {
          _id: id,
        },
        payload: {
          privacypolicy: privacyPolicy,
        },
      };
      updatePolicy(data);
    } else if (section === "terms") {
      let id = tncLink?.data?.[0]?._id;
      let data = {
        query: {
          _id: id,
        },
        payload: {
          terms: terms,
        },
      };
      updateTerms(data);
    }
  };

  return (
    <>
      <div className="pg-container flex flex-col">
        <Header />
        <main className="pg-main bg-white flex-1">
          <div className="section-header">
            Buyer App Information &nbsp;
            {getStorageItem("adminType") === "e-commerce-b2b"
              ? "(B2B)"
              : "(B2C)"}
          </div>
          <div className="pg-subheader mx-80 mt-30">
            <Tabs color="#F0792E" defaultValue="aboutUs">
              <Tabs.List>
                <Tabs.Tab value="aboutUs">About Us</Tabs.Tab>
                <Tabs.Tab value="privacyPolicy">Privacy Policy</Tabs.Tab>
                <Tabs.Tab value="tnc">Terms & Conditions</Tabs.Tab>
                <Tabs.Tab value="FAQ">Frequenty Asked Questions</Tabs.Tab>
                <Tabs.Tab value="contactUs">Contact Us</Tabs.Tab>
              </Tabs.List>

              <Tabs.Panel value="aboutUs">
                <div>
                  <Input.Wrapper
                    className="w-500 mt-30"
                    withAsterisk
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="About Us Link"
                    required
                  >
                    <TextInput
                      placeholder="Enter Link"
                      variant="filled"
                      defaultValue={aboutUsLink?.data?.[0]?.about}
                      onChange={(e) => setAboutUs(e.target.value)}
                      disabled={isUser}
                      // {...offerDetailsForm.getInputProps("couponCode")}
                    />
                  </Input.Wrapper>
                  <div className="flex justify-end mt-10 pt-10 w-500">
                    <Button
                      type="filled-primary"
                      text={
                        aboutUsLink?.data?.[0]?.about
                          ? `Save Changes`
                          : `Save Link`
                      }
                      disabled={
                        isUser || aboutUsLink?.data?.[0]?.about === aboutUs
                      }
                      loading={false}
                      onClick={(e: any) => {
                        if (!aboutUsLink?.data?.[0]?.about) {
                          handleAddLink("about");
                        } else {
                          handleEditLink("about");
                        }
                      }}
                    />
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="privacyPolicy">
                <div>
                  <Input.Wrapper
                    className="w-500 mt-30"
                    withAsterisk
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="Privacy Policy"
                    required
                  >
                    <TextInput
                      placeholder="Enter Link"
                      variant="filled"
                      defaultValue={privacyPolicyLink?.data?.[0]?.privacypolicy}
                      onChange={(e) => setPrivacyPolicy(e.target.value)}
                      disabled={isUser}
                    />
                  </Input.Wrapper>
                  <div className="flex justify-end mt-10 pt-10 w-500">
                    <Button
                      type="filled-primary"
                      text={
                        privacyPolicyLink?.data?.[0]?.privacypolicy
                          ? `Save Changes`
                          : `Save Link`
                      }
                      disabled={
                        isUser ||
                        privacyPolicyLink?.data?.[0]?.privacypolicy ===
                          privacyPolicy
                      }
                      loading={false}
                      onClick={(e: any) => {
                        if (!privacyPolicyLink?.data?.[0]?.privacypolicy) {
                          handleAddLink("privacy");
                        } else {
                          handleEditLink("privacy");
                        }
                      }}
                    />
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="tnc">
                <div>
                  <Input.Wrapper
                    className="w-500 mt-30"
                    withAsterisk
                    classNames={{
                      label: "clr-grey txt-12 txtw-4",
                    }}
                    label="Terms & Conditions"
                    required
                  >
                    <TextInput
                      placeholder="Enter Link"
                      variant="filled"
                      defaultValue={tncLink?.data?.[0]?.terms}
                      onChange={(e) => setTerms(e.target.value)}
                      disabled={isUser}
                      // {...offerDetailsForm.getInputProps("couponCode")}
                    />
                  </Input.Wrapper>
                  <div className="flex justify-end mt-10 pt-10 w-500">
                    <Button
                      type="filled-primary"
                      text={
                        tncLink?.data?.[0]?.terms ? `Save Changes` : `Save Link`
                      }
                      loading={false}
                      disabled={isUser || tncLink?.data?.[0]?.terms === terms}
                      onClick={(e: any) => {
                        if (!tncLink?.data?.[0]?.terms) {
                          handleAddLink("terms");
                        } else {
                          handleEditLink("terms");
                        }
                      }}
                    />
                  </div>
                </div>
              </Tabs.Panel>
              <Tabs.Panel value="FAQ">
                <FAQSection />
              </Tabs.Panel>
              <Tabs.Panel value="contactUs">
                <ContactUs />
              </Tabs.Panel>
            </Tabs>
          </div>
        </main>
      </div>
    </>
  );
};

export default BuyerAppInfo;
