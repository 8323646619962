import {
  Accordion,
  ActionIcon,
  Input,
  Modal,
  TextInput,
  Textarea,
} from "@mantine/core";
import { NoOffersSVG, PencilIconSVG, TrashboxIconSVG } from "assets/icons";
import { Button } from "components/Common/Button";
import { DeleteModal } from "components/Common/DeleteModal";
import { EmptyPage } from "components/Common/EmptyPage";
import {
  useAddContactUsMutation,
  useDeleteContactUsMutation,
  useGetContactUsQuery,
  useUpdateContactUsMutation,
} from "hooks/Admin/buyerAppInfo";
import { useEffect, useState } from "react";
import { getStorageItem } from "services/storage";

type QuestionnaireType = { title: string; description: string; _id?: string };

function ContactSection() {
  const initialContact = {
    title: "",
    description: "",
  };

  const { data: ContactData, refetch } = useGetContactUsQuery();
  const [addContact] = useAddContactUsMutation();
  const [updateContact] = useUpdateContactUsMutation();
  const [deleteContact] = useDeleteContactUsMutation();
  const isUser = getStorageItem("role") === "USER";

  const ContactLinkFromBE = ContactData?.data?.find(
    (item: any) => item.link
  )?.link;

  const [showAddEditContact, setShowAddEditContact] = useState<boolean>(false);
  const [ContactLink, setContactLink] = useState<string>("");

  const [isQuestionnaireThere, setIsQuestionnaireThere] =
    useState<boolean>(false);
  const [showQuestionnaire, setShowQuestionnaire] = useState<boolean>(false);
  const [ContactQuestionnaire, setContactQuestionnaire] =
    useState<QuestionnaireType>(initialContact);
  const [toBeDeletedContact, setToBeDeletedContact] = useState<any>(null);
  const [isRemoveContactModalVisible, setRemoveContactModalVisible] =
    useState<boolean>(false);

  useEffect(() => {
    if (ContactData?.data?.find((item: any) => item.question))
      setIsQuestionnaireThere(true);
    setContactLink(ContactLinkFromBE);
  }, [ContactData, ContactLinkFromBE]);

  const showEditContact = (Contact: QuestionnaireType) => {
    setShowAddEditContact(true);
    const toEdit = ContactData?.data?.find(
      (item: any) => item._id === Contact._id
    );
    setContactQuestionnaire({
      title: toEdit.title,
      description: toEdit.description,
      _id: toEdit._id,
    });
  };

  const onDeleteContact = (data: any) => () => {
    setRemoveContactModalVisible(true);
    setToBeDeletedContact(data._id);
  };

  async function handleRemoveContact() {
    const response = await deleteContact(toBeDeletedContact);
    if (response?.data?.success) {
      refetch();
      setRemoveContactModalVisible(false);
      setToBeDeletedContact(null);
    }
  }

  const handleAddOrUpdateFAQ = async () => {
    const { _id, title, description } = ContactQuestionnaire;

    let APISuccessful;
    if (_id) {
      let data = {
        query: {
          _id,
        },
        payload: {
          title,
          description,
        },
      };
      APISuccessful = await updateContact(data);
    } else {
      let payload = {
        ...ContactQuestionnaire,
      };
      APISuccessful = await addContact(payload);
    }

    if (APISuccessful) {
      refetch();
      setShowAddEditContact(false);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleAddOrUpdateLink = () => {
    if (!ContactLinkFromBE) {
      addContact({ link: ContactLink });
    } else {
      let payload = {
        link: ContactLink,
        isBusiness:
          getStorageItem("adminType") === "e-commerce-b2b" ? true : false,
      };
      let id = ContactData?.data?.find((item: any) => item.link)?._id;

      let data = {
        query: {
          _id: id,
        },
        payload: payload,
      };
      updateContact(data);
    }
  };

  return (
    <div className="mt-30 w-500">
      <div className="w-full flex justify-stretch align-center mb-20">
        {/* <Button
          type="subtle"
          text={
            showQuestionnaire
              ? ""
              : isQuestionnaireThere
              ? "Edit Details"
              : "Add Details"
          }
          loading={false}
          onClick={() => setShowQuestionnaire(!showQuestionnaire)}
          disabled={isUser}
        /> */}

        <Button
          type="filled-primary"
          text="Add Contact Us Details"
          loading={false}
          onClick={() => {
            setContactQuestionnaire(initialContact);
            setShowAddEditContact(true);
          }}
        />
      </div>

      {ContactData?.data ? (
        <>
          {showAddEditContact && (
            <Modal
              opened={showAddEditContact}
              title={
                ContactQuestionnaire._id ? "Edit Contact Us" : "Add Contact Us"
              }
              onClose={() => setShowAddEditContact(false)}
            >
              <Input.Wrapper
                className="mt-30"
                withAsterisk
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="Enter Title"
                required
              >
                <TextInput
                  placeholder="Enter Title"
                  variant="filled"
                  value={ContactQuestionnaire.title}
                  onChange={(e) =>
                    setContactQuestionnaire((prev) => ({
                      ...prev,
                      title: e.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
              <Input.Wrapper
                className="my-30"
                withAsterisk
                classNames={{
                  label: "clr-grey txt-12 txtw-4",
                }}
                label="Enter Description"
                required
              >
                <Textarea
                  classNames={{ input: "min-h-100" }}
                  placeholder="Enter Description"
                  variant="filled"
                  value={ContactQuestionnaire.description}
                  onChange={(e) =>
                    setContactQuestionnaire((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </Input.Wrapper>
              <Button
                type="filled-primary"
                text="Save Changes"
                loading={false}
                onClick={handleAddOrUpdateFAQ}
              />
            </Modal>
          )}
          <div>
            <Accordion>
              {ContactData?.data
                ?.filter((item: any) => item.title)
                ?.map((item: any) => (
                  <div key={item._id} className="flex justify-stretch">
                    <Accordion.Item
                      key={item._id}
                      value={item.title}
                      className="w-500"
                    >
                      <Accordion.Control>
                        <div className="flex justify-stretch">
                          {item.title}{" "}
                          <div style={{ display: "flex" }}>
                            <ActionIcon onClick={() => showEditContact(item)}>
                              <PencilIconSVG />
                            </ActionIcon>
                            <ActionIcon onClick={onDeleteContact(item)}>
                              <TrashboxIconSVG />
                            </ActionIcon>
                          </div>
                        </div>
                      </Accordion.Control>
                      <Accordion.Panel>{item.description}</Accordion.Panel>
                    </Accordion.Item>
                  </div>
                ))}
            </Accordion>
          </div>
        </>
      ) : (
        <EmptyPage img={NoOffersSVG} item="Details" />
      )}
      <DeleteModal
        isDeleteModalOpen={isRemoveContactModalVisible}
        closeDeleteModal={() => setRemoveContactModalVisible(false)}
        data={{
          text: "question",
        }}
        onConfirm={handleRemoveContact}
      />
    </div>
  );
}

export default ContactSection;
