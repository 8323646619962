import { createContext, useContext, useState, useEffect } from "react";
import { INITIAL_ORDERS_FILTERS } from "constants/index";
import {
  getStorageItem,
  removeStorageItem,
  setStorageItem,
} from "services/storage";

const FiltersContext = createContext<any>({});

export const FiltersProvider = ({ children }: { children: any }) => {
  const [isFiltersModalVisible, setIsFiltersModalVisible] =
    useState<boolean>(false);

  const [orderfilterCount, setOrderFilterCount] = useState<any>(() => {
    const storedFilterCount = getStorageItem("orderfilterCount");

    if (storedFilterCount) {
      return storedFilterCount;
    }
    return 0;
  });
  const [settlementfilterCount, setSettlementFilterCount] = useState<any>(
    () => {
      const storedFilterCount = getStorageItem("settlementfilterCount");

      if (storedFilterCount) {
        return storedFilterCount;
      }
      return 0;
    }
  );

  const [filters, setFilters] = useState<any>(() => {
    const storedFilters = getStorageItem("filters");
    if (storedFilters) {
      return storedFilters;
    }
    return INITIAL_ORDERS_FILTERS;
  });
  const [ordersSettlementFilters, setOrdersSettlementFilters] = useState<any>(
    () => {
      const storedFilters = getStorageItem("settlementFilters");
      if (storedFilters) {
        return storedFilters;
      }
      return INITIAL_ORDERS_FILTERS;
    }
  );
  const clearFiltersFromStorage = () => {
    removeStorageItem("orderfilters");
    removeStorageItem("settlementFilters");
    removeStorageItem("orderfilterCount");
    removeStorageItem("settlementfilterCount");
    setFilters(INITIAL_ORDERS_FILTERS);
    setOrderFilterCount(0);
    setSettlementFilterCount(0);
  };

  useEffect(() => {
    setStorageItem("orderfilters", filters);
  }, [filters]);
  useEffect(() => {
    setStorageItem("settlementFilters", ordersSettlementFilters);
  }, [ordersSettlementFilters]);
  useEffect(() => {
    setStorageItem("settlementfilterCount", settlementfilterCount);
  }, [settlementfilterCount]);
  useEffect(() => {
    setStorageItem("orderfilterCount", orderfilterCount);
  }, [orderfilterCount]);
  return (
    <FiltersContext.Provider
      value={{
        isFiltersModalVisible,
        setIsFiltersModalVisible,
        filters,
        setFilters,
        orderfilterCount,
        settlementfilterCount,
        setOrderFilterCount,
        setSettlementFilterCount,
        clearFiltersFromStorage,
        ordersSettlementFilters,
        setOrdersSettlementFilters,
      }}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export const useFilters = () => useContext(FiltersContext);
