export const constant: any = {
  order: [
    { label: "Status", value: "status" },
    { label: "date-time", value: "date-time" },
  ],
  settlements: [{ label: "date-time", value: "date-time" }],
  customers: [{ label: "Status", value: "status" }],
  ticketManagement: [
    {
      label: "Issue Status",
      value: "issueStatus",
    },
  ],
};
