import { Divider, Input, Textarea, Timeline } from "@mantine/core";
import styles from "./TicketManegement.module.scss";
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOrdersSVG } from "assets/icons";
import { TimelineProps } from "types/ticket";
import { getDisplayDate, getDisplayTime } from "utils/helper";
import { Dropzone } from "components/Common/Dropzone";
import ImagePreview from "components/Common/ImagePreview/ImagePreview";
import { Button } from "components/Common/Button";
import { useForm } from "@mantine/form";
import { useState } from "react";
import { useLazyGetTicketDetailsQuery, useLazySendReplyQuery } from "hooks/Admin/ticketAPI";
import { useNotification } from "context";
import { useParams } from "react-router-dom";

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const replyHistory = (timeline: any[]) => {
  return timeline.map(item => {
    const personName = item.updated_by?.person?.name || "Unknown";
    const shortDescWithPersonName = `${item.short_desc} ${personName}`;
    return {
      ...item,
      short_desc: capitalizeFirstLetter(shortDescWithPersonName),
      timeline_action: item.complainant_action ?? item.respondent_action ?? "",
    };
  });
};

export default function ReplyHistory({ timeline, id }: TimelineProps) {
  const processedreply = replyHistory(timeline);

  const takeActionForm = useForm<any>({
    initialValues: {
      description: "",
    },
  });
  const [issueImage, setIssueImage] = useState<any>(null);
  const imageLinks = issueImage?.map((item: any) => item?.link);
  const handleDeleteImage = (index: number) => {
    setIssueImage((prev: any) => prev?.filter((_: any, i: number) => i !== index));
  };
  const { setText } = useNotification();
  const [triggerSendComments] = useLazySendReplyQuery();

  async function handlereply() {
    const payload = {
      issueId: id,
      ...takeActionForm.values,
      ...((issueImage ?? [])?.length && { files: issueImage }),
    };

    const response = await triggerSendComments({
      body: payload,
    });
    if (response?.data?.success) {
      setText("Reply Sent Successfully");
      takeActionForm.reset();
      setIssueImage(null);
      handleRefresh();
    }
  }

  const [triggerGetTicketDetails] = useLazyGetTicketDetailsQuery();

  const params = useParams();
  const selectedComplaintId = params.id;
  const handleRefresh = () => {
    if (selectedComplaintId) {
      triggerGetTicketDetails({ _id: selectedComplaintId });
    }
  };

  return processedreply && processedreply.length > 0 ? (
    <div className="flex flex-col my-32">
      <div className="px-12 flex flex-col gap-20">
        <Timeline
          active={processedreply?.length}
          bulletSize={32}
          lineWidth={2}
          classNames={{ itemBullet: styles.bullet, item: styles.line }}>
          {processedreply.map(({ author, description, updatedAt, files }) => (
            <Timeline.Item key={description} bullet={<span className={styles["bullet-marker"]} />}>
              <div className="ml-13 txt-16">
                <span>
                  <p className="txtw-10 clr-black">
                    {author}: {description}
                  </p>
                </span>
                {files?.length && files?.length > 0
                  ? files?.map((file: { link: string }, index: number) => (
                      <img key={index} src={file?.link} alt="Uploaded File" width={100} height={100} />
                    ))
                  : ""}
                <p className="txtw-2 clr-grey">
                  {getDisplayDate(updatedAt)}, {getDisplayTime(updatedAt)}
                </p>
              </div>
            </Timeline.Item>
          ))}
        </Timeline>
      </div>

      <div>
        <p className="txt-18 txtw-6 mt-24 mb-16">Reply</p>
        <div className="px-32 flex flex-col">
          <div className="flex">
            <p className="w-300 txt-14 txtw-6 clr-grey mr-10">Reply to issue</p>
            <Input.Wrapper
              withAsterisk
              classNames={{
                label: "txt-12 txtw-4",
              }}
              className="flex justify-center"
              required>
              <Textarea
                variant="filled"
                {...takeActionForm.getInputProps("description")}
                aria-multiline
                classNames={{
                  input: "w-full min-w-400 max-w-512 h-150 bg-white bd-full brd-10",
                }}
              />
            </Input.Wrapper>
          </div>
          <Divider color="#D0D5DD" className="my-24" />
          <div className="flex">
            <p className="w-300 txt-14 txtw-6 clr-grey mr-10">Attach images</p>
            <div className="flex justify-center w-400">
              <Dropzone
                mediaTypes={["image/png", "image/jpeg", "image/svg+xml"]}
                media={issueImage || ""}
                setMedia={setIssueImage}
                mediaValidationText={""}
                setMediaValidationText={() => {}}
              />
              {/* <ImagePreview imagesList={imageLinks} /> */}
              <ImagePreview imagesList={imageLinks} onDelete={handleDeleteImage} />
            </div>
          </div>

          <div className="align-self-end mt-24 flex gap-20">
            <Button type="outline" text="Cancel" onClick={() => console.log("cancel")} />
            <Button type="filled-primary" text="Reply" onClick={() => handlereply()} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <EmptyPage item=" Reply History Found" img={NoOrdersSVG} />
  );
}
