import CustomTable from "components/CustomTable/CustomTable";
import { useGetTicketsListQuery } from "hooks/Admin/ticketAPI";
import styles from "./TicketManegement.module.scss";
import {
  ComplaintTableFields,
  IssueStatusType,
  TicketAPIOptionsType,
} from "types/ticket";
// import { PaginationContainer } from "components/Common/PaginationContainer"
import { EmptyPage } from "components/Common/EmptyPage";
import { NoOffersSVG, ViewStatusSVG } from "assets/icons";
import { createColumnHelper } from "@tanstack/react-table";
import { getDisplayDate } from "utils/helper";
import RoleStatusBadge from "components/Common/Badge/RoleStatus";
import { Divider } from "@mantine/core";
import OptionsBarContainer from "components/Common/OptionsBarContainer/OptionsBarContainer";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Header } from "components/Common/Header";

export default function Complaints() {
  const params = useParams();
  const issueStatus = params.status;

  const [ticketAPIOptions, setTicketAPIOptions] =
    useState<TicketAPIOptionsType>({
      page: 1,
      size: "10",
      search: "",
      issueStatus:
        issueStatus === "all" ? null : (issueStatus as IssueStatusType),
    });

  const onFiltersChange = (value: string) => {
    setTicketAPIOptions((prev: TicketAPIOptionsType) => ({
      ...prev,
      params: value,
    }));
  };

  const { data: ticketsList } = useGetTicketsListQuery(ticketAPIOptions);

  const tableData = ticketsList?.data?.map(
    ({ _id, ticketId, order_details, category }: any) => ({
      ...order_details,
      _id,
      category,
      ticketId,
    })
  );

  const columnHelper = createColumnHelper<ComplaintTableFields>();

  const columns = [
    columnHelper.accessor("ticketId", {
      header: "Ticket ID",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("title", {
      header: "Title",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("category", {
      header: "Category",
      enableSorting: false,
      cell: (info) => <span>{info.getValue()}</span>,
    }),
    columnHelper.accessor("ticket_created_at", {
      header: "Created On",
      enableSorting: false,
      cell: (info) => <span>{getDisplayDate(info.getValue())}</span>,
    }),
    columnHelper.accessor("ticket_updated_at", {
      header: "Updated On",
      enableSorting: false,
      cell: (info) => <span>{getDisplayDate(info.getValue())}</span>,
    }),
    columnHelper.accessor("status", {
      header: "Issue Status",
      enableSorting: false,
      cell: (info) => (
        <div className="txt-14 txtw-5">
          <RoleStatusBadge type={info.getValue() || ""}>
            <span
              className={`marker ${
                info.getValue()?.toLowerCase() === "closed" ? "red" : "green"
              }`}
            />
          </RoleStatusBadge>
        </div>
      ),
    }),
    columnHelper.accessor("ticketId", {
      header: "Action",
      enableSorting: false,
      cell: (info: any) => (
        <div className={styles.action}>
          <Link
            to={`/tickets-management/${issueStatus}/${info.row.original._id}`}
          >
            <ViewStatusSVG />
          </Link>
        </div>
      ),
      size: 80,
    }),
  ];
  const onSearchChange = (value: string) => {
    setTicketAPIOptions((prev) => ({
      ...prev,
      search: value,
    }));
  };

  return (
    <div className="pg-container flex flex-col">
      <Header />
      <main
        className="pg-main bg-white flex-1"
        style={{ position: "relative" }}
      >
        <div className="flex mr-80 justify-stretch align-center">
          <div className="section-header" style={{ marginBottom: "30px" }}>
            Ticket Management
          </div>
        </div>
        <Divider className="mx-80 pb-20" color="#D0D5DD" />
        {/* {issueStatus === "all" && ()} */}

        <div className="mx-80 pb-20">
          <OptionsBarContainer
            placeholder="Search for complaints"
            selectedTab="ticketManagement"
            onSearchChange={onSearchChange}
            onFiltersChange={onFiltersChange}
            isSearch={true}
            isSort={false}
          />
        </div>

        {!!tableData?.length ? (
          <>
            <section
              className="min-h-70 bd-gray  flex-col bg-white mt-5 mb-5 mx-80 mb-40"
              style={{ overflowX: "scroll" }}
            >
              <CustomTable
                columns={columns}
                data={tableData}
                className={styles.table}
                search={ticketAPIOptions?.search}
              />
            </section>
          </>
        ) : (
          <EmptyPage img={NoOffersSVG} item="Complaints" />
        )}
      </main>
    </div>
  );
}
